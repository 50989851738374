body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Josefin Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body, html, div#root {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline: none;
}

::selection {
  background: transparent;
  color: #fff;
}

::-moz-selection {
  background: transparent;
  color: #fff;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

em-emoji-picker {
  --background-rgb: 85, 170, 255;
  --border-radius: 24px;
  --category-icon-size: 24px;
  --color-border-hover: rgba(0, 0, 0, 0.1);
  --color-border: rgba(0, 0, 0, 0.05);
  --font-family: 'Josefin Sans', sans-serif;
  --font-size: 20px;
  --rgb-accent: 143, 153, 204;
  --rgb-background: 82, 92, 139;
  --rgb-color: 143, 153, 204;
  --rgb-input: 255, 235, 235;

  height: 40vh;
  min-height: 300px;
  max-height: 600px;
}
